import React from 'react';

import Chapter from '../chapter/chapter';

import './about.scss';

import {Link} from 'gatsby';

class About extends React.Component {
  render() {
    return (
      <section className="about extraspace noselect" id="about">
        <main className="small">
          <Chapter icon="address-card">
            <h2>About</h2>
          </Chapter>

          <p>
            Hi! I'm Meysam. I'm glad you found your way to my personal corner of the internet. Here (and <mark><Link to="https://meysam.dev/blog">here</Link></mark>) is where I like to keep a record of my projects, hobbies, and occasional thoughts about random things.
          </p>
        </main>
      </section>
    );
  }
}

const about = () => <About />;
export default about;
